import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SiteMetadata from '../components/SiteMetadata/SiteMetadata';
import styles from './404.module.css';

const Error404 = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle} location={location}>
      <SiteMetadata title="Not Found" pathname="/404" />
      <div className={styles.container}>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Error404;
